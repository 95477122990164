<template>
    <div class="main">
      <div class="art box-popup">
      <div class="banner-01"></div>
      <div class="txt txt-01">MUDANÇAS COMERCIAIS</div>
      <div class="txt txt-02">MUDANÇAS RESIDENCIAIS</div>
      <div class="txt txt-03">CARRETOS EM GERAL</div>
      </div>
      <div class="art">
          <div class="mg-top">
            <h5 class="ds">SOBRE NOS</h5>
          </div>
          <div class="ds">
            <div class="teste ">
              <div class="col-inner" style="max-width:520px;">
                <p>A Machado Fretes e Mudanças trabalha com qualidade e compromisso, oferecendo comodidade e segurança a todos os clientes que buscam pelos nossos serviços em todo Brasil.</p>
                <p>Com uma frota variada de veículos, contamos com a opção mais adequada para cada tipo de mudança, sempre realizada com rapidez e praticidade e a preços competitivos no mercado. Nossos funcionários são capacitados e especializados em realizar desmontagem e montagem de móveis com agilidade e máximo cuidado, além de embalar seus móveis e pertences com os melhores e mais variados materiais.</p>
              </div>
              <div class="col-inner" style="max-width:520px;">
                <p>E para oferecer um serviço completo, a Machado Fretes e Mudanças ainda dispõe de depósito para armazenagem, sempre sob rígido controle de vigilância. Entre em contato conosco. Nossa equipe está pronta para encontrar as melhores soluções para sua demanda. Realizamos orçamentos sem compromisso.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="art">
          <div class="mg-top">
            <h5 class="ds">NOSSOS SERVIÇOS</h5>
          </div>
          <div class="ds">
            <div class="teste ">
              <div class="">
                  <div><img src="@/assets/icon-home.svg"></div>
                  <h5>MUDANÇAS RESIDENCIAIS</h5>
                  <p>Mudança Residencial e Comercial para Campinas e região, ou longas distâncias (interestaduais), com o melhor preço.</p>
              </div>
              <div class="c">
                  <div><img src="@/assets/icon-tools.svg"></div>
                  <h5>MONTAGEM DE MÓVEIS</h5>
                  <p>Além do transporte, temos serviço de Desmontagem e Montagem de Móveis para Mudanças Comerciais e Residenciais.</p>
              </div>
              <div class="c">
                  <div><img src="@/assets/icon-car.svg"></div>
                  <h5>FRETES COMERCIAIS</h5>
                  <p>Caminhão Baú para transporte de Produtos ou Mercadorias. Apoio logístico para seu Comércio ou Empresa.</p>
              </div>
              <div class="c">
                  <div><img src="@/assets/icon-truck.svg"></div>
                  <h5>CARRETOS EM GERAL</h5>
                  <p>Você precisa de pequenos transportes em Campinas e região? A Machado Fretes e Mudanças esta pronta para atender você! Temos Pick-up, Van, Caminhão baú.</p>
              </div>
            </div>
          </div>
        </div>
      <div class="art">
        <a href="https://api.whatsapp.com/send?phone=5519994665907&amp;text=Olá%20Machado%20Fretes%20e%20Mudanças,%20gostaria%20de%20um%20orçamento." class="btn just-c-c"><span><img src="@/assets/whatsapp-32.svg"></span></a>
        <div class="mg-top">
            <h5 class="ds">CONTATOS</h5>
        </div>
        <div>
          <div class="mg-top">
            <h5 class="title">MACHADO FRETES E MUDANÇAS</h5>
              <div class="inline">
                <div><a href="tel:19994665907" class="cl-333"><img src="@/assets/map.svg"><span>&nbsp;Campinas/Sp</span></a></div>
                <div><a href="tel:19994665907" class="cl-333"><img src="@/assets/phone-32.svg"><span>&nbsp;(19)&nbsp;9&nbsp;9466-5907</span></a></div>
                <div><a href="https://api.whatsapp.com/send?phone=5519994665907&amp;text=Olá%20Machado%20Fretes%20e%20Mudanças,%20gostaria%20de%20um%20orçamento." class="cl-333"><img src="@/assets/whats-32.svg"><span>&nbsp;(19)&nbsp;9&nbsp;9466-5907</span></a></div>
                <div><a href="mailto:machadofretesemudancas@gmail.com" class="cl-333"><img src="@/assets/envelope-32.svg"><span>&nbsp;machadofretesemudancas@gmail.com</span></a></div>
              </div>
               <div class="mg-top">
                <h5 class="">Siga-nos</h5>
              </div>
            <div class="mg-top">
              <a href="https://www.instagram.com/machadofretesemudancas/"><span><img src="@/assets/instagram-logo-32.svg"></span></a>&nbsp;
              <a href="https://www.facebook.com/machadofretesemudancas/"><span><img src="@/assets/facebook-logo-32.svg"></span></a>
            </div>
            <div class="mg-top">
              <p class="by">&copy;&nbsp;{{year}},&nbsp;Todos&nbsp;os&nbsp;direitos&nbsp;reservados</p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name: 'main',
  data: function () {
    return {
      year: ''
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
    this.year = new Date().getFullYear()
  },
  methods: {
    handleScroll () {
      const boxes = document.querySelectorAll('.ds')
      const triggerBottom = (window.innerHeight / 5) * 4
      boxes.forEach((box) => {
        const boxTop = box.getBoundingClientRect().top
        if (boxTop < triggerBottom) {
          box.classList.add('show')
        }
      })
    },
    pg (x, h) {
      const sections = document.querySelectorAll('.art')
      window.scrollTo(0, sections[x].offsetTop - h)
    }
  }
}
</script>
<style scoped lang="scss">
$n:none;
$abs:absolute;
$rltv:relative;
$p_100:100%;
$w_100:100vw;
$w_90:90vw;
$h_90:90vh ;
$h_100:100vh ;
$c_ffffff:#ffffff;
// $c_0c0c0c:#0c0c0c;
// $c_f8b500:#f8b500;
$c_1310BE:#1310BE;
// $c_057e01:#057e01;
$c_20d366:#20d366;
$n:none;
$rltv:relative;
$p_100:100%;
$w_100:100vw;
$h_100:100vh ;
$c_ffffff:#ffffff;
$c_0c0c0c:#0c0c0c;
$c_1310BE:#1310BE;
/* @import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css?family=Arima+Madurai&display=swap'); */

.by {
  font-family: 'Arima Madurai', cursive;
}
.main{
  position:$rltv;
  min-width:$w_100;
  display:flex;
  flex-direction: column;
}
.art{
  position:$rltv;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: $h_100;
  margin-bottom: 50px;
}
.box-popup{
  min-width:$w_100;
  min-height:$h_100;
  overflow:hidden;
  position:$rltv;
  background: $c_1310BE;
}
.mg-top{
  margin-top: 50px;
}
@keyframes banner-01 {
    0% {right:0px;}
     35% {right:  10px;}
     45% {right:  0px;}
     50% {right:  10px;}
     60% {right:  0px;}
     90% {right:  10px;}
    100% {right:0px;}
}
.banner-01{
  position:$abs;
  bottom:0;
  background-image: url('../assets/banner/banner-01.png');
  background-size: cover;
  background-repeat: no-repeat;
  animation:banner-01 15s ease-out 0s normal infinite;
  width:$p_100;
  height: $p_100;
  z-index:4;
}
@keyframes txt-01 {
      0% {z-index: 5;bottom:30px;}
      10% {z-index: 5;bottom:30px;}
      20% {z-index: 5;bottom:-100px;}
      35% {z-index: -1;}
      45% {z-index: -1;}
      50% {z-index: -1;}
      60% {z-index: -1;}
      90% {z-index: -1;}
      100% {z-index: -1;}
}
@keyframes txt-02 {
      0% {z-index: -1;}
      10% {z-index: -1;}
      20% {z-index: -1;}
      35% {z-index: 5;bottom:30px;}
      45% {z-index: 5;bottom:30px;}
      50% {z-index: 5;bottom:-100px;}
      60% {z-index: -1;}
      90% {z-index: -1;}
      100% {z-index: -1;}
}
@keyframes txt-03 {
      0% {z-index: -1;}
      10% {z-index: -1;}
      20% {z-index: -1;}
      35% {z-index: -1;}
      45% {z-index: -1;}
      50% {z-index: -1;}
      60% {z-index: 5;bottom:30px;}
      90% {z-index: 5;bottom:30px;}
      100% {z-index: 5;bottom:-100px;}
}
.txt{
  position:$abs;
  right: 130px;
  color: $c_ffffff;
  bottom:-100px;
  font-family:'Poppins';
}
.txt-01{
  animation:txt-01 15s ease-out 0s infinite normal;
  z-index: -1;
}
.txt-02{
  animation:txt-02 15s ease-out 0s infinite normal;
}
.txt-03{
  animation:txt-03 15s ease-out 0s infinite normal;
  z-index: -1;
}
.ds {
  transform:translateY(400%);
  transition:transform 0.4s ease;
}
.ds:nth-of-type(even) {
  transform:translateY(-400%);
}
.ds.show {
  transform:translateY(0);
}
.just-c-c{
  display:flex;
  align-items:center;
  justify-content:center;
}
.teste{
  display:inline-flex;
  max-width:$w_90;
  min-width:$w_90;
  justify-content:space-evenly;
}
.inline{
  display:inline-flex;
  max-width:$w_90;
  min-width:$w_90;
  justify-content:space-evenly;
}
.inline div{
  min-width: calc(100%/4);
}
.inline div a{
  display:inline-flex;
  align-items: center;
}
.teste div{
  flex-grow:4;
  padding:20px;
}
.teste .c{
  margin-left:2px;
}
.btn {
  position:fixed;
  width:70px;
  height:70px;
  bottom:40px;
  right:40px;
  z-index:6;
  background: $c_20d366;
  border-radius: 1000px;
  transition:all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: $n;
  padding: 10px;
}
.btn::before {
 content:'';
  border-radius:100px;
  min-width:calc(70px + 6px);
  min-height:calc(70px + 6px);
  border:3px solid $c_20d366;
  position:$abs;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  opacity:0;
  transition:all .3s ease-in-out 0s;
}
.btn:hover, .btn:focus {
  color:#313133;
  transform:translateY(-6px);
}
.btn:hover::before, .btn:focus::before {
  opacity:1;
}
.btn::after {
  content:'';
  width:10px; height:10px;
  border-radius:$p_100;
  border:6px solid $c_20d366;
  position:$abs;
  z-index:-1;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  animation:ring 1.5s infinite;
}
.btn:hover::after, .btn:focus::after {
  animation:$n;
  display:$n;
}
@keyframes ring {
  0% {
    width:30px;
    height:30px;
    opacity:1;
  }
  100% {
    width:150px;
    height:150px;
    opacity:0;
  }
}
@media (max-width:800px) {
.inline{
  display:flex;
  flex-direction: column;
}
.teste{
  display:flex;
  flex-direction: column;
}
.inline  div{
  margin:12px 0;
}
.inline div a{
 display:flex;
}
.title{
  margin-bottom: 50px;
}
.navigation{
  background:$c_20d366;
}
.btn {
  bottom:80px;
  right:40px;
}
}
</style>
