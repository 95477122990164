<template>
 <div class="navigation">
          <div class="nav01">
            <div class="logo">
              <img alt="" class="responsive" src="@/assets/logo.svg">
            </div>
            <div class="div-icon">
              <a class="icon" @click="m" to="/">
                <span></span>
                <span></span>
                <span></span>
              </a>
            </div>
          </div>
          <div class="nav" v-on:scroll="handleScroll">
            <a @click="exec(0)" to="/">HOME</a>
            <a @click="exec(1)" to="/">SOBRE</a>
            <a @click="exec(2)" to="/">SERVIÇOS</a>
            <a @click="exec(3)" to="/">CONTATO</a>
          </div>
        </div>
 <hello-world ref="child"></hello-world>
</template>
//"^(\s)*$\n"
<script>
import HelloWorld from './components/HelloWorld.vue'
export default {
  components: { HelloWorld },
  name: 'app',
  methods: {
    exec (x) {
      var elmnt = document.querySelector('.nav01')
      this.$refs.child.pg(x, elmnt.offsetHeight)
      this.m(x)
    },
    m () {
      var icon = document.querySelector('.icon')
      var nv = document.querySelector('.nav')
      if (icon.classList.length === 2) {
        icon.classList.remove('open')
        nv.classList.remove('opennav')
      } else {
        icon.classList.add('open')
        nv.classList.add('opennav')
      }
    }
  }
}
</script>
<style scoped lang="scss">
$n:none;
$rltv:relative;
$abs:absolute;
$p_100:100%;
$w_100:100vw;
$h_100:100vh;
$c_ffffff:#ffffff;
$c_0c0c0c:#0c0c0c;
$c_1310BE:#1310BE;
$r_0:rotate(0deg);
$t_5s:.5s ease-in-out;
$t_25s:.25s ease-in-out;
// @import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

.icon {
  width: 60px;
  height: 45px;
  position: $rltv;
  margin: 50px auto;
  -webkit-transform: $r_0;
  -moz-transform: $r_0;
  -o-transform: $r_0;
  transform: $r_0;
  -webkit-transition: $t_5s;
  -moz-transition: $t_5s;
  -o-transition: $t_5s;
  transition: $t_5s;
  cursor: pointer;
}
.icon span {
  display: block;
  position: $abs;
  height: 3px;
  width: 100%;
  background:$c_1310BE;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  -webkit-transform: $r_0;
  -moz-transform: $r_0;
  -o-transform: $r_0;
  transform: $r_0;
  -webkit-transition: $t_25s;
  -moz-transition: $t_25s;
  -o-transition: $t_25s;
  transition: $t_25s;
}
/* Icon*/
.icon span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.icon span:nth-child(2) {
  top: 18px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.icon span:nth-child(3) {
  top: 36px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
.icon.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
}
.icon.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}
.icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 39px;
  left: 8px;
}
#app {
  max-width:$w_100;
  min-width:$w_100;
}
.logo{
  float:left;
  /* max-width:176px; */
  min-width:176px;
  background-position:center;
  background-repeat:no-repeat;
  background-size:cover;
}
.navigation{
  position:fixed;
 max-width:$w_100;
  min-width:$w_100;
  z-index:7;
  background:$c_ffffff;
}
.nav01{
  float: left;
}
.icon{
  margin-right: 20px;
  display: none;
}
.nav {
  float: right;
  margin-right: 15px;
  a {
    float:left;
    position:$rltv;
    padding:10px 20px 6px 20px;
    line-height:50px;
    color:$c_0c0c0c;
    &:hover {
      padding:10px 20px 3px 20px;
      border-bottom:3px solid $c_1310BE;
    }
    }
  }

@media (max-width:800px) {
  .navigation{
    flex-direction:column;
  }
  .nav{
    float: left;
    display:none;
    min-width: $p_100;
  }
  .nav01{
    display: flex;
    justify-content:space-between;
    align-items: center;
    min-width: $p_100;
  }
  .nav.opennav{
    display:flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 4px;
  }
  .icon{
    display: block;
  }
}
@media (max-width:320px) {
  p{
    font-size:4vw;
  }
  h2{
    font-size:3.6vw;
  }
  h3{
    font-size:3vw;
  }
  span{
    font-size:3.8vw;
  }
}
</style>
